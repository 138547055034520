import { http } from "../UppAuth";
import { dateTimeZoneAdjusted } from "../util/date";

export interface UppWorkflowEvent {
  tenantId?: string;
  channel?: string;
  user?: string;
  date?: Date;
  step?: number;
  parentWorkflowId?: string;
  workflowId?: string;
  workflowName?: string;
  type?: string;
  action?: string;
  inputs?: any;
  outputs?: any;
}

export interface UppWorkflowCounts {
  all: number;
  assetGroups: number;
  cancelAssetGroups: number;
  productTypeExclusion: number;
  cancelProductTypeExclusion: number;
  customLabelExclusion: number;
  cancelCustomLabelExclusion: number;
  limitedByBudget: number;
}

export async function getUppWorkflowEvents(
  offset: number,
  limit: number,
  from: Date,
  to: Date,
  workflowType: string
): Promise<UppWorkflowEvent[]> {
  const fromParam = from ? `from=${dateTimeZoneAdjusted(from)}&` : "";
  const toParam = to ? `to=${dateTimeZoneAdjusted(to)}&` : "";
  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/upp-workflows/events?pageSize=${limit}&skip=${offset}&${fromParam}&${toParam}&workflowType=${workflowType}`
  );
  if (result.status === 200) {
    return result.data.events;
  }
  return [];
}

export async function getUppWorkflowCounts(from: Date, to: Date): Promise<UppWorkflowCounts> {
  const fromParam = from ? `from=${dateTimeZoneAdjusted(from)}&` : "";
  const toParam = to ? `to=${dateTimeZoneAdjusted(to)}&` : "";

  const result = await http.get(
    `${window.__RUNTIME_CONFIG__.REACT_APP_SVC_URL}/upp-workflows/events/count?${fromParam}&${toParam}`
  );
  if (result.status === 200) {
    return result.data;
  }
  return {
    all: 0,
    assetGroups: 0,
    cancelAssetGroups: 0,
    productTypeExclusion: 0,
    cancelProductTypeExclusion: 0,
    customLabelExclusion: 0,
    cancelCustomLabelExclusion: 0,
    limitedByBudget: 0,
  };
}
