import Icon from "@material-ui/core/Icon";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../../components/Card";
import FooterButton from "../../components/FooterButton";
import InternalButton from "../../components/InternalButton";
import UppWorkflowEventsTable from "./UppWorkflowEventsTable";
import {
  getUppWorkflowCounts,
  getUppWorkflowEvents,
  UppWorkflowCounts,
  UppWorkflowEvent,
} from "../../api/uppWorkflows";

const Column = styled.div`
  display: grid;
  grid-auto-columns: max-content;
`;

const Content = styled.div`
  margin-top: 5em;
`;

const UppWorkflowEventsHeader = styled.div`
  height: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1em;
`;

export enum WorkflowType {
  ALL = "all",
  ASSET_GROUPS = "assetGroups",
  CANCEL_PRODUCT_TYPE_EXCLUSION = "cancelProductTypeExclusion",
  CUSTOM_LABEL_EXCLUSION = "customLabelExclusion",
  LIMITED_BY_BUDGET = "limitedByBudget",
  CANCEL_CUSTOM_LABEL_EXCLUSION = "cancelCustomLabelExclusion",
  CANCEL_ASSET_GROUPS = "cancelAssetGroups",
  PRODUCT_TYPE_EXCLUSION = "productTypeExclusion",
}

const UppWorkflowEvents = () => {
  const [page, setPage] = useState<number>(0);
  const [workflowType, setWorkflowType] = useState<WorkflowType>(WorkflowType.ALL);
  const [counts, setCounts] = useState<UppWorkflowCounts>({
    all: 0,
    assetGroups: 0,
    cancelAssetGroups: 0,
    productTypeExclusion: 0,
    cancelProductTypeExclusion: 0,
    customLabelExclusion: 0,
    cancelCustomLabelExclusion: 0,
    limitedByBudget: 0,
  });
  const [uppWorkflowEvents, setUppWorkflowEvents] = useState<UppWorkflowEvent[]>([]);
  const [isLastPage, setIsLastPage] = useState<boolean>(true);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const startDate = DateTime.now().minus({ years: 1 }).toJSDate();
  const endDate = DateTime.now().toJSDate();

  const changeFilter = (workflowType: WorkflowType): void => {
    setPage(0);
    setWorkflowType(workflowType);
  };

  useEffect(() => {
    let isMounted = true;

    async function getCounts() {
      const counts = await getUppWorkflowCounts(startDate, endDate);
      if (isMounted) setCounts(counts);
    }
    getCounts();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isMounted = true;

    async function getEvents() {
      const items = await getUppWorkflowEvents(page * 20, 20, startDate, endDate, workflowType);
      if (isMounted) setUppWorkflowEvents(items);
    }
    getEvents();
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [page, workflowType]);

  useEffect(() => {
    let isMounted = true;
    async function getIsLastPage() {
      if (isMounted) {
        const itemCount = page * 20 + uppWorkflowEvents.length;
        setIsLastPage(itemCount >= counts[workflowType]);
      }
    }
    getIsLastPage();
    return () => {
      isMounted = false;
    };
  }, [page, uppWorkflowEvents, counts, workflowType]);

  return (
    <Content>
      <Column>
        <UppWorkflowEventsHeader>
          <ButtonWrapper>
            <InternalButton active={workflowType === WorkflowType.ALL} onClick={() => changeFilter(WorkflowType.ALL)}>
              {`All Workflows (${counts.all})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.ASSET_GROUPS}
              onClick={() => changeFilter(WorkflowType.ASSET_GROUPS)}
            >
              {`Asset Groups (${counts.assetGroups})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.CANCEL_ASSET_GROUPS}
              onClick={() => changeFilter(WorkflowType.CANCEL_ASSET_GROUPS)}
            >
              {`Cancel Asset Groups (${counts.cancelAssetGroups})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.CUSTOM_LABEL_EXCLUSION}
              onClick={() => changeFilter(WorkflowType.CUSTOM_LABEL_EXCLUSION)}
            >
              {`Custom Label Exclusion (${counts.customLabelExclusion})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.CANCEL_CUSTOM_LABEL_EXCLUSION}
              onClick={() => changeFilter(WorkflowType.CANCEL_CUSTOM_LABEL_EXCLUSION)}
            >
              {`Cancel Custom Label Exclusion (${counts.cancelCustomLabelExclusion})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.PRODUCT_TYPE_EXCLUSION}
              onClick={() => changeFilter(WorkflowType.PRODUCT_TYPE_EXCLUSION)}
            >
              {`Product Type Exclusion (${counts.productTypeExclusion})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.CANCEL_PRODUCT_TYPE_EXCLUSION}
              onClick={() => changeFilter(WorkflowType.CANCEL_PRODUCT_TYPE_EXCLUSION)}
            >
              {`Cancel Product Type Exclusion (${counts.cancelProductTypeExclusion})`}
            </InternalButton>
            <InternalButton
              active={workflowType === WorkflowType.LIMITED_BY_BUDGET}
              onClick={() => changeFilter(WorkflowType.LIMITED_BY_BUDGET)}
            >
              {`Limited by Budget (${counts.limitedByBudget})`}
            </InternalButton>
          </ButtonWrapper>
        </UppWorkflowEventsHeader>
        <Card>
          <UppWorkflowEventsTable page={page} workflowType={workflowType} uppWorkflowEvents={uppWorkflowEvents}>
            <FooterButton
              disabled={page < 1}
              onClick={() => {
                page > 0 && setPage(page - 1);
              }}
            >
              <Icon className="material-icons-outline">{"<<"}</Icon>
            </FooterButton>
            <>Current Page: {page + 1}</>
            <FooterButton
              disabled={isLastPage}
              onClick={() => {
                !isLastPage && setPage(page + 1);
              }}
            >
              <Icon className="material-icons-outlined">{">>"}</Icon>
            </FooterButton>
          </UppWorkflowEventsTable>
        </Card>
      </Column>
    </Content>
  );
};

export default UppWorkflowEvents;
